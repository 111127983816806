import React, { useState } from 'react';
import { useTranslations } from './TranslationContext';
import type { Language } from './types';

const LanguageSelector: React.FC = () => {
  const { setLanguageCode, languageCode, translations } = useTranslations();

  const [languages] = useState<Language[]>([
    { id: '1', code: 'EN', languageCode: 'EN', translatedName: 'English' },
    { id: '2', code: 'HI', languageCode: 'HI', translatedName: 'हिंदी' },
    { id: '3', code: 'TE', languageCode: 'TE', translatedName: 'తెలుగు' },
    { id: '4', code: 'TA', languageCode: 'ES', translatedName: 'Spanish' },
    // Add more languages as needed
  ]);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const languageCode = event.target.value;
    setLanguageCode(languageCode); // This will trigger the translation update in TranslationContext
  };

  return (
    <div>
      <label htmlFor="language-dropdown">Select Language: </label>
      <select id="language-dropdown" value={languageCode} onChange={handleLanguageChange}>
        {languages.map((language) => (
          <option key={language.id} value={language.languageCode}>
            {language.translatedName || language.languageCode}
          </option>
        ))}
      </select>

      <h2>Translated Categories</h2>
      <ul>
        {translations.map((category) => (
          <li key={category.id}>{category.translatedName || category.code}</li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;

// import React, { useState } from 'react';
// import { useTranslations } from './TranslationContext';
// import type { Language } from './types';
// import Categories from '../Dropdown categories/Dropdowncategoryies';
//
// const LanguageSelector: React.FC = () => {
//   const { setLanguageCode, languageCode } = useTranslations();
//
//   const [languages] = useState<Language[]>([
//     { id: '1', code: 'EN', languageCode: 'EN', translatedName: 'English' },
//     { id: '2', code: 'HI', languageCode: 'HI', translatedName: 'हिंदी' },
//     { id: '3', code: 'TE', languageCode: 'TE', translatedName: 'తెలుగు' },
//     { id: '4', code: 'ES', languageCode: 'ES', translatedName: 'Spanish' },
//     // Add more languages as needed
//   ]);
//
//   const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
//     const languageCode = event.target.value;
//     setLanguageCode(languageCode); // This will trigger the translation update in TranslationContext
//   };
//
//   return (
//     <div>
//       <label htmlFor="language-dropdown">Select Language: </label>
//       <select id="language-dropdown" value={languageCode} onChange={handleLanguageChange}>
//         {languages.map((language) => (
//           <option key={language.id} value={language.languageCode}>
//             {language.translatedName || language.languageCode}
//           </option>
//         ))}
//       </select>
//
//       <h2>Translated Categories</h2>
//       <Categories languageCode={languageCode} />
//     </div>
//   );
// };
//
// export default LanguageSelector;

// LanguageSelector.tsx
