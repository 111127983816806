// TranslationContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import type { Translations } from './types';

interface TranslationContextProps {
  translations: Translations;
  languageCode: string;
  setLanguageCode: (code: string) => void;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [translations, setTranslations] = useState<Translations>([]);
  const [languageCode, setLanguageCode] = useState<string>('EN'); // Default language

  // Fetch translations whenever the languageCode changes
  useEffect(() => {
    axios
      .get<Translations>(
        `https://adzmonster.com/api/categories/allTranslated?languageCode=${languageCode}`,
      )
      .then((response) => {
        setTranslations(response.data); // Store translations in context state
      })
      .catch((error) => {
        console.error('Error fetching translations:', error);
      });
  }, [languageCode]);

  return (
    <TranslationContext.Provider value={{ translations, languageCode, setLanguageCode }}>
      {children}
    </TranslationContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useTranslations = (): TranslationContextProps => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslations must be used within a TranslationProvider');
  }
  return context;
};
