import categoriesData from './categoriesData.json';
import './Categories.css';
import type { Category } from '@features/auth/types/category.types.ts';

interface DropdowncategoryiesProps {
  categories?: Category[];
}

// eslint-disable-next-line no-empty-pattern
const Categories = ({}: DropdowncategoryiesProps) => {
  return (
    <div className="categories-container">
      {categoriesData.categories.map((category, index) => (
        <div key={index} className="category-column">
          <h3 className="category-name">{category.categoryName}</h3>
          <ul className="subcategories-list">
            {category.subCategories.map((subCategory, idx) => (
              <li key={idx} className="subcategory-item">
                {subCategory}
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="see-all">
        <a href="/all-categories" className="see-all-link">
          See all categories &gt;
        </a>
      </div>
    </div>
  );
};

export default Categories;

// import React, { useEffect, useState } from 'react';
// import './Categories.css';
// import type { Category } from '@features/auth/components/Dropdown categories/Dropdown.types.ts';
//
// interface DropdowncategoryiesProps {
//   languageCode: string;
// }
//
// const Categories: React.FC<DropdowncategoryiesProps> = ({ languageCode }) => {
//   const [categories, setCategories] = useState<Category[]>([]);
//   const [error, setError] = useState<string | null>(null);
//
//   useEffect(() => {
//     const fetchCategories = async () => {
//       try {
//         const response = await fetch(
//           `https://adzmonster.com/api/categories/allTranslated?languageCode=${languageCode}`,
//         );
//         if (!response.ok) {
//           throw new Error(`Error fetching categories: ${response.statusText}`);
//         }
//         const data = await response.json();
//         if (Array.isArray(data)) {
//           setCategories(data);
//         } else {
//           throw new Error('Invalid data format');
//         }
//       } catch (err) {
//         if (err instanceof Error) {
//           setError(err.message);
//         } else {
//           setError('An unknown error occurred');
//         }
//         console.error('Error fetching categories:', err);
//       }
//     };
//
//     if (languageCode) {
//       fetchCategories();
//     }
//   }, [languageCode]);
//
//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }
//
//   return (
//     <div className="categories-container">
//       {categories.map((category) => (
//         <div key={category.id} className="category-column">
//           <h3 className="category-name">{category.translatedName || category.code}</h3>
//           <ul className="subcategories-list">
//             {category.childCategories.map((subCategory) => (
//               <li key={subCategory.id} className="subcategory-item">
//                 {subCategory.translatedName || subCategory.code}
//               </li>
//             ))}
//           </ul>
//         </div>
//       ))}
//       <div className="see-all">
//         <a href="/all-categories" className="see-all-link">
//           <Categories languageCode={languageCode} />
//           See all categories &gt;
//         </a>
//       </div>
//     </div>
//   );
// };
//
// export default Categories;
