// src/features/auth/components/Home/Home.tsx

import React, { useState } from 'react';
import { ShoppingCart, LogIn, UserPlus } from 'lucide-react';
import '../../CSS/Home.css';
import { useNavigate } from 'react-router-dom';
import Footer from '@features/auth/components/Home/Footer.tsx';
import Logo from '@features/auth/components/Logo/Logo.tsx';
import { categories } from '@/features/auth/data/categoryData.ts';
import SearchAds from '@features/auth/components/Searchbar-with-advanced-searchbar/SearchAds.tsx';
import { Route, Routes } from 'react-router-dom';
import RealEstatePage from '@features/auth/components/Category/RealEstatePage.tsx';
import HSCategory from '../../components/Category/HSCategory.tsx';
import HomeServicesData from '../../data/homeServicesData.json' assert { type: 'json' };
import EventAndEntertainmentServices from '@/features/auth/components/Category/EventAndEntertainmentServices.tsx';
import LanguageSelector from '@features/auth/components/LanguageSelector/LanguageSelector.tsx';
// import { useTranslations } from '@features/auth/components/LanguageSelector/TranslationContext.tsx';

const UserProfile = () => {
  const navigate = useNavigate();

  const handlelogin = () => {
    navigate('/auth/login');
  };
  const handlesignup = () => {
    navigate('/auth/registrationform');
  };

  return (
    <div className="cart">
      <ShoppingCart className="logo-icon" />
      <div className="user-profile" onClick={handlelogin}>
        <LogIn className="login" />
        <span>Login</span>
      </div>
      <div className="user-profile" onClick={handlesignup}>
        <UserPlus className="signup" />
        <span>Signup</span>
      </div>
    </div>
  );
};

interface CategoryItemProps {
  title: string;
  imageUrl: string;
  adCount: number;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ title, imageUrl, adCount }) => (
  <div className="category-item">
    <img src={imageUrl} alt={title} className="category-image" />
    <h3 className="category-title">{title}</h3>
    <p className="ad-count">{adCount} Ads Available</p>
  </div>
);

export default function Home() {
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  // const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // const { languageCode } = useTranslations();
  //
  const handleCategoryClick = (categoryName: string) => {
    if (categoryName === 'Real Estate') {
      navigate('/real-estate');
    } else if (categoryName === 'Home Services') {
      navigate('/homeservices');
    } else if (categoryName === 'Event and Entertainment Services') {
      navigate('/eventsandentertainmentservices');
    } else {
      navigate(`/category/${categoryName}`);
    }
  };

  return (
    <div className="home-page">
      <header className="header">
        <div className="header-container">
          <div onClick={() => navigate('/')}>
            <Logo altText="ADZ Monster Logo" className="logo" />
          </div>
          <LanguageSelector />
          <UserProfile />
        </div>
      </header>
      <div className="searchbar-wrap">
        <SearchAds onSearch={setIsSearching} />
      </div>

      {!isSearching && (
        <main className="main-content">
          <h1 className="main-title">Explore Our Categories</h1>
          <div className="category-grid">
            {categories.map((category, index) => (
              <div key={index} onClick={() => handleCategoryClick(category.categoryName)}>
                <CategoryItem
                  title={category.categoryName}
                  imageUrl={category.image}
                  adCount={category.totalAds}
                />
              </div>
            ))}
          </div>
        </main>
      )}
      <Routes>
        <Route path="/real-estate" element={<RealEstatePage />} />
        <Route path="/homeservices" element={<HSCategory data={HomeServicesData} />} />
        <Route path="/eventsandentertainmentservices" element={<EventAndEntertainmentServices />} />
      </Routes>
      <Footer />
    </div>
  );
}
