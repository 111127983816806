import React, { useState, useEffect } from 'react';
import adsData from '@/types/ads/categorytest.json';
import './SearchAds.css';
import type { AdData } from '@/types/AdData';
import { useNavigate } from 'react-router-dom';
import Dropdowncategoryies from '@features/auth/components/Dropdown categories/Dropdowncategoryies.tsx';
import { categories } from '@features/auth/data/categoryData.ts';
import Modal from '../../components/PopUpModel/PopUpModal.tsx';

interface SearchAdsProps {
  onSearch: (isSearching: boolean) => void;
}

const SearchAds: React.FC<SearchAdsProps> = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAds, setFilteredAds] = useState<AdData[]>([]);
  const [showBaseCategories, setShowBaseCategories] = useState(true);
  const navigate = useNavigate();

  const handlePostAdClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/auth/postad');
  };
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredAds([]); // Clear results if the search query is empty
      onSearch(false); // Notify parent that search is not active
      return;
    }
    const query = searchQuery.toLowerCase();

    // Filter ads based on the search query
    const results = adsData.filter((ad) => {
      const realEstateMatch = ad.realEstateAd && ad.metadata.category.toLowerCase().includes(query);
      const automobileMatch = ad.automobileAd && ad.metadata.category.toLowerCase().includes(query);
      const subCategoryMatch = (ad.metadata.subCategory || '').toLowerCase().includes(query);

      return realEstateMatch || automobileMatch || subCategoryMatch;
    });

    setFilteredAds(results as unknown as AdData[]);
    setShowBaseCategories(query === '');
    onSearch(true);
  }, [searchQuery, onSearch]);
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  return (
    <div className="search-ads">
      <form className="search-bar">
        <div className="search-bar-wrapper">
          <button className="postad-button" onClick={handlePostAdClick}>
            PostAd
          </button>
          <div className="searchbar-button">
            <input
              type="text"
              placeholder="Search ads (e.g., car, apartment)"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            <button type="button" onClick={toggleDropdown} className="category-dropdown">
              All Categories
            </button>
            {isDropdownVisible && <Dropdowncategoryies categories={categories} />}
            <button type="submit" className="search-button">
              Search
            </button>
          </div>
        </div>
      </form>
      <Modal isVisible={isDropdownVisible} onClose={toggleDropdown}>
        <Dropdowncategoryies />
      </Modal>

      <div className="ads-list">
        <div className="ads-list-header">
          {!showBaseCategories && filteredAds.length === 0 ? (
            <div className="no-results">
              <img src="#" alt="No results found" />
            </div>
          ) : (
            <div className={`show-ads ${!showBaseCategories ? 'hide-categories' : ''}`}>
              {filteredAds.map((ad) => (
                <div key={ad.metadata.id} className="ad-card">
                  <div className="ad-image">
                    <img src="#" alt={ad.metadata.title} />
                  </div>
                  <div className="ad-details">
                    <h3>{ad.metadata.title}</h3>
                    <p>{ad.metadata.description}</p>
                    {ad.realEstateAd ? (
                      <p>
                        {ad.realEstateAd.bedrooms} bds | {ad.realEstateAd.bathrooms} ba |{' '}
                        {ad.realEstateAd.size} sqft
                      </p>
                    ) : ad.automobileAd ? (
                      <p>
                        {ad.automobileAd.make} {ad.automobileAd.model} - ${ad.automobileAd.price}
                      </p>
                    ) : null}
                    <p>{ad.metadata.category}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchAds;

// import React, { useState, useEffect } from 'react';
// import adsData from '@/types/ads/categorytest.json';
// import './SearchAds.css';
// import type { AdData } from '@/types/AdData';
// import { useNavigate } from 'react-router-dom';
// import Modal from '../../components/PopUpModel/PopUpModal.tsx';
// import Dropdowncategoryies from '@features/auth/components/Dropdown categories/Dropdowncategoryies.tsx';
//
// interface SearchAdsProps {
//   onSearch: (isSearching: boolean) => void;
//   languageCode: string;
// }
//
// const SearchAds: React.FC<SearchAdsProps> = ({ onSearch, languageCode }) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [filteredAds, setFilteredAds] = useState<AdData[]>([]);
//   const [showBaseCategories, setShowBaseCategories] = useState(true);
//   const navigate = useNavigate();
//
//   const handlePostAdClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     navigate('/auth/postad');
//   };
//   const [isDropdownVisible, setIsDropdownVisible] = useState(false);
//
//   useEffect(() => {
//     if (searchQuery === '') {
//       setFilteredAds([]); // Clear results if the search query is empty
//       onSearch(false); // Notify parent that search is not active
//       return;
//     }
//     const query = searchQuery.toLowerCase();
//
//     // Filter ads based on the search query
//     const results = adsData.filter((ad) => {
//       const realEstateMatch = ad.realEstateAd && ad.metadata.category.toLowerCase().includes(query);
//       const automobileMatch = ad.automobileAd && ad.metadata.category.toLowerCase().includes(query);
//       const subCategoryMatch = (ad.metadata.subCategory || '').toLowerCase().includes(query);
//
//       return realEstateMatch || automobileMatch || subCategoryMatch;
//     });
//
//     setFilteredAds(results as unknown as AdData[]);
//     setShowBaseCategories(query === '');
//     onSearch(true);
//   }, [searchQuery, onSearch]);
//
//   const toggleDropdown = () => {
//     setIsDropdownVisible((prev) => !prev);
//   };
//
//   return (
//     <div className="search-ads">
//       <form className="search-bar">
//         <div className="search-bar-animated-border">
//           <div className="search-bar-wrapper">
//             <button className="postad-button" onClick={handlePostAdClick}>
//               PostAd
//             </button>
//             <div className="searchbar-button">
//               <input
//                 type="text"
//                 placeholder="Search ads (e.g., car, apartment)"
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 className="search-input"
//               />
//               <button type="button" onClick={toggleDropdown} className="category-dropdown">
//                 All Categories
//               </button>
//               <button type="submit" className="search-button">
//                 Search
//               </button>
//             </div>
//           </div>
//         </div>
//       </form>
//       <Modal isVisible={isDropdownVisible} onClose={toggleDropdown}>
//         <Dropdowncategoryies languageCode={languageCode} />
//       </Modal>
//
//       <div className="ads-list">
//         <div className="ads-list-header">
//           {!showBaseCategories && filteredAds.length === 0 ? (
//             <div className="no-results">
//               <img src="#" alt="No results found" />
//             </div>
//           ) : (
//             <div className={`show-ads ${!showBaseCategories ? 'hide-categories' : ''}`}>
//               {filteredAds.map((ad) => (
//                 <div key={ad.metadata.id} className="ad-card">
//                   <div className="ad-image">
//                     <img src="#" alt={ad.metadata.title} />
//                   </div>
//                   <div className="ad-details">
//                     <h3>{ad.metadata.title}</h3>
//                     <p>{ad.metadata.description}</p>
//                     {ad.realEstateAd ? (
//                       <p>
//                         {ad.realEstateAd.bedrooms} bds | {ad.realEstateAd.bathrooms} ba |{' '}
//                         {ad.realEstateAd.size} sqft
//                       </p>
//                     ) : ad.automobileAd ? (
//                       <p>
//                         {ad.automobileAd.make} {ad.automobileAd.model} - ${ad.automobileAd.price}
//                       </p>
//                     ) : null}
//                     <p>{ad.metadata.category}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default SearchAds;
