import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/features/auth/components/Home/Home.tsx';
import { LoginForm } from '@features/auth/components/LoginForm/LoginForm.tsx';
import { Registrationform } from '@features/auth/components/RegistrationForm/registrationform.tsx';
import SubcategoryPage from '@/features/auth/components/Home/SubcategoryPage.tsx';
import RealEstatePage from '@/features/auth/components/Category/RealEstatePage.tsx';
import HSCategory from '@/features/auth/components/Category/HSCategory.tsx';
import HomeServicesData from '@/features/auth/data/homeServicesData.json' assert { type: 'json' };
import EventAndEntertainmentServices from '@/features/auth/components/Category/EventAndEntertainmentServices.tsx';
import { TranslationProvider } from '@/features/auth/components/LanguageSelector/TranslationContext.tsx';
import LanguageSelector from '@/features/auth/components/LanguageSelector/LanguageSelector.tsx';

export default function App() {
  return (
    <TranslationProvider>
      <Router>
        <Routes>
          <Route path="/v1" element={<Home />} />
          <Route path="/auth/login" element={<LoginForm />} />
          <Route path="/auth/registrationform" element={<Registrationform />} />
          <Route path="/category/:categoryName" element={<SubcategoryPage />} /> {/* New route */}
          <Route path="/real-estate" element={<RealEstatePage />} />
          <Route path="/homeservices" element={<HSCategory data={HomeServicesData} />} />
          <Route
            path="/eventsandentertainmentservices"
            element={<EventAndEntertainmentServices />}
          />
          <Route path="/v1" element={<LanguageSelector />} />
        </Routes>
      </Router>
    </TranslationProvider>
  );
}
