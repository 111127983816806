import type React from 'react';
import type { LoginFormProps } from '@/features/auth/components/LoginForm/LoginForm';
import type { DemoComponent } from '@/demo/types/demo.types.ts';

class ComponentRegistry {
  private static instance: ComponentRegistry;
  private components: Map<string, DemoComponent> = new Map();

  private constructor() {}

  static getInstance(): ComponentRegistry {
    if (!ComponentRegistry.instance) {
      ComponentRegistry.instance = new ComponentRegistry();
    }
    return ComponentRegistry.instance;
  }

  register(
    id: string,
    demo: {
      argTypes: { onError: { action: string }; onSuccess: { action: string } };
      path: string;
      component: React.FC<LoginFormProps>; // Enforce type here
      title: string;
      parameters: { docs: { description: string } };
    },
  ) {
    this.components.set(id, <DemoComponent>demo);
  }

  getAll(): DemoComponent[] {
    return Array.from(this.components.values());
  }

  get(id: string): DemoComponent | undefined {
    return this.components.get(id);
  }
}

export const componentRegistry = ComponentRegistry.getInstance();
